.text-area-container {
  width: 100%;
  padding-bottom: 0.5em;
}

.text-area-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e8ed;
  border-radius: .5em;
  background-color: #fff;
  height: 150px;
}

.autonomous-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}


.text-area {
  background-color: #fff;
  border: 1px solid #e1e8ed;
  border-radius: .5em .5em 0 0;
  padding: 1.5em;
  resize: vertical;
  outline: none;
  height: 150px;
  resize: none;
}

.submit-button {
  background-color: #ffdec0;
  border: none;
  color: #000033;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 0 0 .5em .5em;
  padding: 10px 24px;
}

.mask-submit-button {
  background-color: #ffdec0;
  border: none;
  color: #000033;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: .5em;
  padding: 10px 24px;
}

.switch-container {
  display: flex;
  flex-direction: row;
  padding-top: .25em;
} 

.switch-container p {
  padding-top: .1em;
  padding-left: .25em;
  padding-right: .25em;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffdec0;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: black;
  -webkit-transition: .4s;
  transition: .4s;
}

input:focus + .slider {
  box-shadow: 0 0 1px black;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.pill-container {
  margin-top: .5em;
  margin-bottom: .5em;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.pills {
  cursor: pointer;
  border-radius: .5em;
  background-color: #ffdec0;
  padding: .25em;
  margin: 0.25em;
  font-size: .85em;
  padding: 1em;
  white-space: nowrap; 
}