.lords-table-popup-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.lords-table-popup {
  position: relative;
  height: 50%;
  width: 80%;
  z-index: 1;
  padding: 10px;
  border-radius: 5px;
}