.selected-lord {
  display: flex;
  flex-direction: column;
}

.unselected-lord {
  height: 150px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffdec0;
  border-radius: .5em;
  /* margin-top: 1.65em; */
}

.unselected-lord p {
  text-align: center;
  font-size: 1.5em;
  cursor: pointer;
}

.select-lord-text {
  text-align: left;
  font-size: 1em;
  cursor: pointer;
  padding-bottom: .25em;
}

.image-container {
  position: relative;
  cursor: pointer;
  padding-bottom: .25em;
}

.lord-image {
  height: 150px;
  width: 150px;
  border-radius: .5em;
}

.lord-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.name {
  font-size: 1em;
  padding-bottom: .25em;
  font-weight: bold;
  text-align: left;
  color: #ffdec0;
}

.customize-icons {
  color: #ffdec0;
}

.attribute {
  font-size: 1em;
  text-align: left;
  color: #ffdec0;
}

.pill-container {
  width: 100%;
  margin-top: .5em;
  margin-bottom: .5em;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.pills {
  cursor: pointer;
  border-radius: .5em;
  background-color: #ffdec0;
  padding: .25em;
  margin: 0.25em;
  font-size: .85em;
  white-space: nowrap;
}