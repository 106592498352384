.feed {
  height: calc(100vh - 1em);
  padding-top: 5%;
  padding-left: 20%;
  padding-right: 20%;
  overflow: scroll;
}

.lord-post {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.content p {
  color: #ffdec0;
}
