.header {
  width: 100%; 
  justify-content: space-between;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  text-align: center;
  font-size: 1em;
}

.wallet, .buy, .discord, .symposium {
  color: #ffdec0;
  padding: 0.3em; 
  font-size: 1.25em;
  cursor: pointer;
}

.left, .right {
  display: flex;
}