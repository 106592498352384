.gif {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.gif-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}


.logo {
  width: 300px;
}

.logo-mobile {
  /* width: 55%; */
}

.item {
  position: absolute;
}