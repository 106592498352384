.customize_container {
  width: 100%;
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  padding-left: .5em;
}

.pill-container {
  border: 1px solid #ffdec0;
  height: 35px;
  width: 100%;
  border-radius: .5em;
  justify-content: flex-start;
  overflow: scroll;
  scrollbar-width: none;
}

/* For webkit-based browsers (e.g., Chrome, Safari) */
/* You can also use ::-webkit-scrollbar-thumb to customize the scrollbar appearance */
pill-container::-webkit-scrollbar {
  width: 0.5em; /* Adjust this value to change the scrollbar width (optional) */
}

pill-container::-webkit-scrollbar-track {
  background: transparent; /* Set the scrollbar track color to transparent */
}

pill-container::-webkit-scrollbar-thumb {
  background: transparent; /* Set the scrollbar thumb color to transparent */
}

.selection-header {
  text-align: left;
  color: #ffdec0;
  font-size: .75em;
}

.search {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 0.5em;
}

.text-input {
  font-size: 1em;
  border: 1px solid #ffdec0;
  border-radius: .5em 0 0 .5em;
  text-align: center;
  outline: none;
  width: 100%;
  padding: 10px 24px;
}

.add-button {
  background-color: #ffdec0;
  border: none;
  color: #000033;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  cursor: pointer;
  border-radius: 0 .5em .5em 0;
  padding: 10px 24px;
}

.pills {
  cursor: pointer;
  border-radius: .5em;
  background-color: #ffdec0;
  padding: .5em;
  font-size: .75em;
  display: inline-block;
}