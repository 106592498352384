.container {
  /* max-width: 600px; */
  margin: 0 auto;
  padding: 20px;
}

.message-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.message {
  background-color: black;
  border: 1px solid black;
  border-radius: .5em;
  padding: 1.5em;
  margin-top: .25em;
  display: flex;
  flex-direction: column;
}

.message-mobile {
  background-color: black;
  border: 1px solid black;
  /* border-radius: .5em; */
  padding: 1.5em;
  /* margin-bottom: 1em; */
  display: flex;
  flex-direction: column;
}

.message:hover {
  /* background-color: #1F0023; */
}

.message p {
  margin: 0;
  word-wrap: break-word;
}

.message-detail {
  overflow-y: auto;
  /* width: 600px; */
}

.message-detail-mobile {
  overflow-y: auto;
  /* width: 100%; */
}

.icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  padding-left: .5em;
  padding-right: .5em;
}

.avatar {
  align-self: flex-start;
  margin-right: 1em;
}

.maintext {
  padding-left: .5em;
  padding-top: .5em;
}

.subtext {
  /* padding-bottom: .5em; */
  padding-left: .5em;
  font-size: .85em;
  font-weight: bold;
  /* color: #828282; */
  /* align-self: flex-end; */
}

.icon {
  /* width: 20px; */
  /* height: 20px; */
  /* background-color: #aaa; */
  cursor: pointer;
}

.customize-icons {
  color: #ffdec0; /* Change the color here */
}


.inline-container {
  display: flex;
  align-items: center; /* If you want to align the items vertically */
  padding-bottom: 0.5em;
}