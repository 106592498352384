.buy-container {
  height: 100%;
  overflow-y: scroll;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.title {
  color: #ffdec0;
  font-size: 2em;
  text-align: center;
  padding-top: 1em;
}

.buy-container p {
  color: #ffdec0;
  padding: 0.3em; 
  font-size: 1.25em;
  cursor: pointer;
  text-align: center;
}

.lords-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 5em;
  margin-bottom: 5em;
}

.collection-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.lyceum-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 5em;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box; /* to make sure padding doesn't affect the width */
  padding: 3em;
}

.lords-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  box-sizing: border-box; /* to make sure padding doesn't affect the width */
  padding: 3em;
}


.checks-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
  box-sizing: border-box; /* to make sure padding doesn't affect the width */
  padding: 3em;
}

.lord-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  text-align: center;
}

.quantity {
  padding-left: 3px;
  color: #020202;
  background-color: #ffdec0;
  font-size: 1em;
  border-radius: 5px;
  border: none;
  padding: 10px 24px;

}

.label {
  color: #ffdec0;
  /* font-weight: bold; */
  font-size: 1.25em;
  padding-top: .5em;
  padding-right: .5em;
  align-items: center;
  vertical-align: center;
}

.mint {
  color: #020202;
  background-color: #ffdec0;
  font-size: 1em;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  align-items: center;
  padding: 10px 24px;
}

.lyceum-image {
  width: 200px;
}

.checks-image {
  width: 150px;
}

.lords-description {
  cursor: default;
  margin-left: 10em;
  margin-right: 10em;
}

.description {
  cursor: default;
  margin-left: 2em;
  margin-right: 2em;
}

.mint-text {
  font-weight: bold;
}