* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  padding: 1em;
  height: calc(100vh);
  display: grid;
  color: black;
  overflow: hidden;
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  /* background-image: url("./images/D_Desktop_Background.png"); */
  /* background-image: url("./images/cyberpunk_1.png"); */
  /* background-image: url("./images/cyberpunk_2.png"); */
  /* background-image: url("./images/cyberpunk_3.png"); */
  /* background-image: url("./images/cyberpunk_4.png"); */
  /* background-image: url("./images/cyberpunk_5.png"); */
  background-image: url("./images/cyberpunk_6.png");
  background-size: cover;
}

h1 {
  font-size: 45px;
}

p {
  font-size: 16px;
  color: #ffdec0;
}

i {
  cursor: pointer;
}

ul {
  list-style-type: none;
}

.date {
  color: white;
}

.detail {
  color: white;
  font-size: 1em;
  text-align: center;
}

a {
  cursor: pointer;
  color: #fee01c;
}

.row {
  display: flex;
  flex-direction: row;
  padding: .5em;
  /* max-width: 400px; */
}

.row p {
  text-align: left;
  font-size: 1em;
}